<template>
  <nav-header :title="$route.meta.title"></nav-header>
  <div class="dao-hang">
    <zi-xun-header/>
  </div>
  <list-page @load="getLoad" ref="xiaLaRef" bottom="70px" top="90px">
    <div v-for="(item) in dataList" :key="item.id">
      <zheng-fu-zi-xun-list :item="item"/>
    </div>
  </list-page>
  <footer-nav fixed/>
</template>


<script>
//服务大厅
import NavHeader from '@/components/top/NavHeader'
import FooterNav from "../open/footerNav";
import http from "../../api/http";
import ZiXunHeader from "@/views/platform/module/ZiXunHeader";
import ZhengFuZiXunList from "@/views/platform/module/ZhengFuZiXunList";
import ListPage from "@/components/list/ListPage";
import XEUtils from "xe-utils";

export default {
  name: 'zheng_fu_zi_xun',
  components: {
    ListPage,
    ZhengFuZiXunList,
    ZiXunHeader,
    NavHeader,
    FooterNav,
  },
  data() {
    return {
      dataList: [],
      xiaLa: null,
    }
  },
  beforeRouteEnter(to, from, next) { // 如果没有配置顶部按钮或isBounce,则beforeRouteEnter不用写
    next(vm => {
      // 滚动到原来的列表位置,恢复顶部按钮和isBounce的配置
      vm.$refs.xiaLaRef && vm.$refs.xiaLaRef.beforeRouteEnter()
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.xiaLaRef && this.$refs.xiaLaRef.beforeRouteLeave()
    next()
  },
  methods: {

    // 获取数据
    getLoad({num, size}) {
      const questParams = {};
      questParams.page = num;
      questParams.data_length = size;
      http.get('/gzhphp/zheng_fu_zi_xun/getNewsList', {params: questParams, load: false}).then(res => {
        if (res.code != 1) return
        if (num === 1) this.dataList = []
        XEUtils.each(res.data.data, n => {
          this.dataList.push(n)
        })
        this.$refs.xiaLaRef.endSuccess(res.data.data.length)
      })

    },
    //从0开始加载页面
    updateList() {
      this.xiaLa.value.resetData()
    }
  }
}
</script>

<style scoped lang="less">
//@import "../../assets/da-ting.less";
.page-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .lie-biao {
    overflow-y: auto;
    flex: 1;
  }
}

</style>
